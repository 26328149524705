<template>
  <div id="app">
    <!-- 路由出口 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    fn () {
      this.$http.get('/app/').then(res => {
        console.log(res)
      })
    }
  }
}
</script>

<style lang="less">

</style>

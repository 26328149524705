import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css'
// 加载全局的样式
import './styles/index.less'

// import MyBreadcrumb from '@/components/my-breadcrumb.vue'

// 一般基于vue的插件, 都要Vue.use一下, 会进行插件的初始化, 会注册组件
Vue.use(ElementUI)
Vue.prototype.$http = axios
Vue.config.productionTip = false
// 注册全局组件
// Vue.component('my-breadcrumb', MyBreadcrumb)

// 新建一个都能访问到的事件总线 => 将来任何vue实例, 都可以 this.$eventBus
Vue.prototype.$EventBus = new Vue()

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
